import { useState, useEffect } from "react";
import { Container, Row, Col} from "react-bootstrap";
import headerImg from "../assets/img/logo-react.png"
import "animate.css";
import TrackVisibility from 'react-on-screen';
import React from 'react';



export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Web Developer", "looking for a work-study program", "looking for a CDI"];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 10)
    const period = 2000;
    useEffect(() => {
        let ticker = setInterval(() =>{
            tick();
        }, delta)
        return () => { clearInterval(ticker)};
    }, [text])
    const tick = () =>{
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i]
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1)

        setText(updatedText);
        if (isDeleting){
            setDelta(prevDelta => prevDelta /2)
        }
        if (!isDeleting && updatedText === fullText){
            setIsDeleting(true);
            setDelta(period);
        } else if(isDeleting && updatedText === ''){
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }
    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <TrackVisibility>
                        {({ isVisible }) =>
                            <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                <span className="tagline">Hello World ! </span>
                                <h1>{`I am Thomas , `}<span className="wrap">{text}</span></h1>
                                <p>My name is Thomas Laizé, and i've been passionate about computers since i was a kid. After training to become a web developer, i'm currently doing a work-study program at Kapsloc.</p>
                            </div>}
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="Headder Img"></img>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}